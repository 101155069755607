import axios from "axios";
import {API_URL} from "./constants";
import axiosInstance from "../interceptor/Interceptor";

const fetchGallupList = () => {
	return axiosInstance
		.get(API_URL + "Gallup/all", {
			headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}
		})
		.then((response) => {
			return response.data;
		});
};

const fetchGallupById = (gallupId) => {
	return axiosInstance
		.get(API_URL + `Gallup/${gallupId}/by_id`, {
			headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`}
		})
		.then((response) => {
			return response.data;
		});
};

const fetchProfReports = (gallupId, regenerate = false) => {
	return axiosInstance
	  .get(API_URL + `Gallup/${gallupId}/prof_reports`, {
		headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
		params: {
		  regenerate: regenerate,
		},
	  })
	  .then((response) => {
		return response.data;
	  })
	  .catch((error) => {
		return error;
	  });
  };

  const fetchProfReportsNew = (gallupId, regenerate = false) => {
	return axiosInstance
	  .get(API_URL + `Gallup/${gallupId}/prof_reports_new`, {
		headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
		params: {
		  regenerate: regenerate,
		},
	  })
	  .then((response) => {
		return response.data;
	  })
	  .catch((error) => {
		return error;
	  });
  };

const downloadFile = (gallupId) => {
    return axiosInstance
        .get(`${API_URL}Gallup/${gallupId}/pdf_similarities_download`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
            responseType: 'text'
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};

const downloadFile_New = (gallupId) => {
    return axiosInstance
        .get(`${API_URL}Gallup/${gallupId}/pdf_similarities_download_new`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
            responseType: 'text'
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};

const chooseProfFields = (gallupId, fields) => {
	return axiosInstance
		.post(`${API_URL}Gallup/${gallupId}/choose_prof_fields`, fields, {
			headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
		})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

const deleteFromDashboard = (gallupId) => {
	return axiosInstance
		.patch(`${API_URL}Gallup/${gallupId}/delete_from_dashboard`, '',{
			headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
		})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
}

export default {
	fetchGallupList,
	fetchGallupById,
	fetchProfReports,
	fetchProfReportsNew,
	downloadFile,
	downloadFile_New,
	chooseProfFields,
	deleteFromDashboard
};
