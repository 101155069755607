import React, {useState} from 'react';
import '../../../assets/css/main.css';
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from 'react-redux'
import {deleteUser as performDeleteUser} from '../../../actions/profileActions';
import { notification } from 'antd';
import { deleteFromDashboard } from '../../../actions/gallupActions';

const UserCard = ({
		name, mode, business = false, photoURL, gallupId, mit, mbti, spheres,
		email, phoneNumber, profSchool, city,
		school, dateOfBirth, grade,
		company, personType,
									}) => {
	const {t} = useTranslation('translation', {keyPrefix: 'dashboard.userCard'})
	const {t: tProfile} = useTranslation('translation', {keyPrefix: 'dashboard.profile'})
 	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [showDeleteUser, setShowDeleteUser] = useState(false);
	const errors = useSelector(state => state.auth.error);
	const profile = useSelector(state => state.profile.userProfile);

	const deleteUser = () => {
		dispatch(deleteFromDashboard(gallupId))
			.then(() => {
				navigate("/dashboard");
			})
			.catch(() => {
				setShowDeleteUser(false);
				notification.warning({
					message: tProfile('deleteAccountError')
				})
			});
			// notification.warning({
			// 	message: tProfile('deleteAccountError')
			// })
	}

	const userEditData = {
		name: name,
		phone: phoneNumber,
		schools: profSchool,
		city: city,
	}

	const studentEditData = {
		user_id: gallupId,
		name: name,
		grade: grade,
		school: school,
		dateOfBirth: dateOfBirth,
		mit: mit,
		mbti: mbti,
		spheres: spheres
	}

	const specialistEditData = {
		user_id: gallupId,
		name: name,
		personType: personType,
		company: company,
		mit: mit,
		mbti: mbti,
		spheres: spheres
	}

	return (
		<div className="bg-[#FFFFFF] p-4 rounded-[20px] flex flex-col">
			{
				mode === 'profile' &&
				<div className="flex justify-end font-semibold">
					<button type='button'
									className={`z-10 relative p-2 hover:bg-red-600 hover:text-white rounded-xl transition-all`}
									onClick={() => setShowDeleteUser(!showDeleteUser)}>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
								 stroke="currentColor" className="size-[28px]">
							<path strokeLinecap="round" strokeLinejoin="round"
										d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"/>
						</svg>

						{showDeleteUser && (
							<div
								onClick={(e) => {
									e.stopPropagation();
								}}
								className='cursor-default absolute top-0 left-0 bg-white lg:p-4 p-2 rounded-[10px] flex flex-col gap-3 ring-1 ring-[#CECECE]
										text-black text-[20px] leading-[23.5px]'>

								<div className="flex flex-col gap-2">
									<div
										className={`${errors ? 'block' : 'hidden'} mt-3 text-[#AD3113] text-[12px]`}>
										{t('errorMessage')}
									</div>
									<div className={`flex flex-col gap-1`}>
										<label className={`text-[16px] leading-[18.8px] text-start`}>Удаление пользователя</label>
									</div>
								</div>
								<div className={`flex flex-col gap-1`}>
									<button 
										onClick={deleteUser}
										className='bg-red-400 p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white'
									>
										Удалить
									</button>
									<button onClick={() => {
										setShowDeleteUser(false)
									}}
													className='bg-[#2596E8] p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white'>
										Отмена
									</button>
								</div>
							</div>
						)}

					</button>
				</div>
			}
			<div className="relative grid place-items-center">
				{photoURL ? (
					<img src={photoURL} className="rounded-full size-[154px] border-[.8em] border-[#474BB1]"
							 alt="User Avatar"/>
				) : (
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
							 stroke="currentColor"
							 className="rounded-full size-[154px] border-[.8em] border-[#474BB1] text-[#474BB1]">
						<path strokeLinecap="round" strokeLinejoin="round"
									d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
					</svg>
				)}
				<span
					className={`${mode === 'profile' ? 'hidden' : 'grid'} place-items-center absolute bottom-[-5%]
					bg-white text-[#393DA1] font-extrabold text-[20px] rounded-full aspect-square w-fit px-3 ring-[3px] ring-[#393DA1]`}>2</span>
			</div>
			{mode === 'profile' && (
				<div className={`flex flex-col gap-4 pt-5`}>
					<div className='flex flex-col items-center'>
						<span className='text-[32px] font-bold leading-[37.6px] text-black'>{name}</span>
						<span className='text-[#787878] text-[20px] leading-[23px]'>{business ? 'Специалист' : 'Школьник'}</span>
					</div>
					{(school && !business) &&
						<div
							className='flex flex-col gap-1 bg-[#F9F9F9] rounded-[15px] p-4 ring-1 ring-[#EEEEEE] text-start text-[16px]'>
							<span className='text-[#5E5E5E] leading-[18.8px]'>{t('school')}</span>
							<span className='text-black font-bold leading-[18.8px]'>{school}</span>
						</div>
					}
					{(dateOfBirth && !business) &&
						<div
							className='flex flex-col gap-1 bg-[#F9F9F9] rounded-[15px] p-4 ring-1 ring-[#EEEEEE] text-start text-[16px]'>
							<span className='text-[#5E5E5E] leading-[18.8px]'>{t('dateOfBirth')}</span>
							<span className='text-black font-bold leading-[18.8px]'>{dateOfBirth}</span>
						</div>
					}
					{(grade && !business) &&
						<div
							className='flex flex-col gap-1 bg-[#F9F9F9] rounded-[15px] p-4 ring-1 ring-[#EEEEEE] text-start text-[16px]'>
							<span className='text-[#5E5E5E] leading-[18.8px]'>{t('class')}</span>
							<span className='text-black font-bold leading-[18.8px]'>{grade}</span>
						</div>
					}
					{(company && business) &&
						<div
							className='flex flex-col gap-1 bg-[#F9F9F9] rounded-[15px] p-4 ring-1 ring-[#EEEEEE] text-start text-[16px]'>
							<span className='text-[#5E5E5E] leading-[18.8px]'>{t('company')}</span>
							<span className='text-black font-bold leading-[18.8px]'>{company}</span>
						</div>
					}
					{(personType && business) &&
						<div
							className='flex flex-col gap-1 bg-[#F9F9F9] rounded-[15px] p-4 ring-1 ring-[#EEEEEE] text-start text-[16px]'>
							<span className='text-[#5E5E5E] leading-[18.8px]'>{t('personType')}</span>
							<span className='text-black font-bold leading-[18.8px]'>{personType}</span>
						</div>
					}
					<Link to={business ? `/specialist/${gallupId}/edit` : `/student/${gallupId}/edit`}
								state={business ? specialistEditData : studentEditData}>
						<button type='button'
										className='bg-[#474BB1] py-3 font-[600] text-[16px] leading-[18.8px] text-white w-full rounded-[10px]'>{t('editData')}</button>
					</Link>
				</div>
			)}
			{mode === 'main' && (
				<div className={`flex flex-col gap-4 pt-5`}>
					<div className='flex flex-col items-center'>
						<span className='text-[32px] font-bold leading-[37.6px] text-black'>{name}</span>
						<span className='text-[#787878] text-[20px] leading-[23px]'>{t('careerGuide')}</span>
					</div>
					<div
						className='flex flex-col gap-1 bg-[#F9F9F9] rounded-[15px] p-4 ring-1 ring-[#EEEEEE] text-start text-[16px]'>
						<span className='text-[#5E5E5E] leading-[18.8px]'>{t('email')}</span>
						<span className='text-black font-bold leading-[18.8px]'>{email}</span>
					</div>
					<div
						className='flex flex-col gap-1 bg-[#F9F9F9] rounded-[15px] p-4 ring-1 ring-[#EEEEEE] text-start text-[16px]'>
						<span className='text-[#5E5E5E] leading-[18.8px]'>{t('phoneNumber')}</span>
						<span className='text-black font-bold leading-[18.8px]'>{phoneNumber}</span>
					</div>
					<div
						className='flex flex-col gap-1 bg-[#F9F9F9] rounded-[15px] p-4 ring-1 ring-[#EEEEEE] text-start text-[16px]'>
						<span className='text-[#5E5E5E] leading-[18.8px]'>Город</span>
						<span className='text-black font-bold leading-[18.8px]'>{city}</span>
					</div>
					<div
						className='flex flex-col gap-1 bg-[#F9F9F9] rounded-[15px] p-4 ring-1 ring-[#EEEEEE] text-start text-[16px]'>
						<span className='text-[#5E5E5E] leading-[18.8px]'>Школа</span>
						<span className='text-black font-bold leading-[18.8px]'>{profSchool}</span>
					</div>
					<Link to="/user/edit" state={userEditData}>
						<button type='button'
										className='bg-[#474BB1] py-3 font-[600] text-[16px] leading-[18.8px] text-white w-full rounded-[10px]'>
							{t('editProfile')}
						</button>
					</Link>
				</div>
			)
			}
		</div>
	);
}

export default UserCard;
